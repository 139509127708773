import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyCJgqQ-RLZ7VFD-U7B3mwjabeJooGkBs3k",
  authDomain: "laladynamics.firebaseapp.com",
  databaseURL: "https://laladynamics-b0ca0-default-rtdb.firebaseio.com",
  projectId: "laladynamics-b0ca0",
};

export const firebaseApp = initializeApp(firebaseConfig);

