import React, { useState, useContext } from 'react';
import {
  Box,
  Container,
  Grid,
  Button,
  Input,
  Heading,
  Text,
  // Image as Img,
} from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';

import { checkValidity } from '../shared/utility';

import { Context as RegistrationContext } from '../contexts/registration/RegistrationContext';

import Image from 'components/image';

// import img1 from 'assets/partner-1-1.png';
// import img2 from 'assets/partner-1-2.png';
// import img3 from 'assets/partner-1-3.png';



const Banner = () => {

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const { state, addRegistration } = useContext(RegistrationContext);

  const HandleRegistration = (event) => {
    event.preventDefault();
    emailValid ? addRegistration({ email }) : console.log("email not valid")
    setEmail('')

  }

  const HandleChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value)
    setEmailValid(checkValidity(email, { isEmail: true }))
  }

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner-image-1-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 958) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (

    <Box sx={styles.banner} id="banner">
      <Container sx={styles.container}>
        <Grid sx={styles.grid}>
          <Box sx={styles.content}>
            <Heading as="h3">
              Lala Dynamics, Your Gateway to Learning Excellence!
            </Heading>
            <Text as="p"> Explore curated courses for all levels and interests. From coding to cooking, there's something for everyone.

            </Text>


            <Text as="p">
              Engage with interactive lessons and projects, and join a vibrant community of learners.
            </Text>
            <Box as="form" sx={styles.form}>
              <Box as="label" htmlFor="subscribe" variant="styles.srOnly">
                subscribe
              </Box>
              <Input
                name="subscribe"
                value={email}
                id="subscribe"
                placeholder="Email to subscribe to our mailing list"
                onChange={HandleChange}
                sx={styles.form.input}
              />
              <Button
                type="submit"
                sx={styles.form.button}
                onClick={HandleRegistration}>
                Subscribe
              </Button>


            </Box>
            <Box sx={styles.content}>

              <Text hidden={state.message === ''} as="h4">
                {
                  state.message === 'success' ?
                    <h4 style={{ color: "#009B72" }}>Thank you for registering. We will reach out to you soon!</h4>
                    : <h4>Something went wrong please make sure that you entered a correct email!</h4>
                }
              </Text>
            </Box>
            {/* <Box sx={styles.partner}>
              <Text as="span">Sponsored by:</Text>
              <Box as="div">
                <Img src={img1} alt="" />
              </Box>
              <Box as="div">
                <Img src={img2} alt="" />
              </Box>
              <Box as="div">
                <Img src={img3} alt="" />
              </Box>
            </Box> */}
          </Box>
          <Box sx={styles.image}>
            <Image src={data.placeholderImage.childImageSharp.fluid} alt="" />
          </Box>
        </Grid>
      </Container>
    </Box>

  );
};

export default Banner;

const styles = {
  banner: {
    pt: ['110px', null, null, null, '150px', '200px'],
    pb: ['50px', null, null, null, '60px', null, '0'],
    backgroundColor: '#F6F8FB',
    overflow: 'hidden',
  },
  container: {
    width: [null, null, null, null, null, null, '1390px'],
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
    gridGap: '0',
  },
  content: {
    h3: {
      color: 'black',
      fontWeight: 'bold',
      lineHeight: [1.39],
      letterSpacing: ['-.7px', '-1.5px'],
      mb: ['15px', null, null, null, '20px'],
      width: ['100%'],
      maxWidth: ['100%', null, null, '90%', '100%', '540px'],
      fontSize: [6, null, null, '36px', null, '55px', 9],
    },
    p: {
      fontSize: [1, null, null, 2, null, 3],
      lineHeight: ['26px', null, null, null, 2.33],
      color: 'text_secondary',
      mb: ['20px', null, null, null, null, '30px'],
      width: ['100%'],
      maxWidth: ['100%', null, null, null, null, '410px'],
      br: {
        display: ['none', null, null, null, 'inherit'],
      },
      h4: {
        color: '#009B72',
        fontWeight: 'bold',
        lineHeight: [1.39],
        letterSpacing: ['-.7px', '-1.5px'],
        mb: ['15px', null, null, null, '20px'],
        width: ['100%'],
        maxWidth: ['100%', null, null, '90%', '100%', '540px'],
        fontSize: [6, null, null, '36px', null, '55px', 9],

      },
    },
  },
  form: {
    mb: ['30px', null, null, null, null, '60px'],
    display: ['flex'],
    input: {
      borderRadius: ['4px'],
      backgroundColor: '#fff',
      width: ['240px', null, null, null, '315px', null, '375px'],
      height: ['45px', null, null, '55px', null, null, '65px'],
      padding: ['0 15px', null, null, '0 25px'],
      fontSize: [1, null, null, 2],
      border: 'none',
      outline: 'none',
      boxShadow: '0px 10px 50px rgba(48, 98, 145, 0.08)',
    },
    button: {
      fontSize: [1, null, null, null, 2, '20px'],
      borderRadius: ['4px'],
      padding: ['0 15px'],
      ml: ['10px'],
      width: ['auto', null, null, null, '180px'],
    },
  },
  image: {
    img: {
      display: 'flex',
      mixBlendMode: 'darken',
      position: 'relative',
      top: ['0', null, null, null, null, '-40px'],
      maxWidth: ['100%', null, null, null, null, null, 'none'],
    },
  },
  partner: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    mb: ['40px'],
    '> div + div': {
      ml: ['10px', null, null, '20px', null, '30px'],
    },
    img: {
      display: 'flex',
    },
    span: {
      fontSize: [1, null, null, null, 2],
      color: '#566272',
      lineHeight: [1],
      opacity: 0.6,
      display: 'block',
      mb: ['20px', null, null, null, '0px'],
      mr: [null, null, null, null, '20px'],
      flex: ['0 0 100%', null, null, null, '0 0 auto'],
    },
  },
};
